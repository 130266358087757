.App {
  height: 100vh;
  min-width: 700px;
  min-height: 500px;

  padding-left: 20px;
  padding-right: 20px;

  background-color: #212121;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.HorizontalContentFill {
  width: 100%;
  max-width: 1800px;
}

.HeaderContainer {
  position: absolute;
  top: 0;

  display: flex;
  justify-content: center;
}

.Header {
  flex: 1;
  padding-left: 20px;

  display: flex;
  align-items: center;
}

.Header h3 {
  background-color: #d15f43;
  font-size: calc(20px + 1.3vw);
  font-weight: 900;
  color: #18181b;
  padding-top: 10px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 6px;
}

@media screen and (min-width: 1400px) {
  .Header h3 {
    font-size: 40px;
  }
}

.ContentContainer {
  z-index: 1;
  flex: 1;

  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 58% 42%;
}

.MapContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ListContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FooterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer {
  position: absolute;
  z-index: 999;

  bottom: 0;
  left: 0;

  height: 50px;
  width: 100%;
  padding-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer p {
  margin-left: 20px;
  margin-right: 20px;
  font-size: calc(5px + 1vw);
  color: #888888;
}

@media screen and (min-width: 900px) {
  .Footer p {
    font-size: 14px;
  }
}

.Footer a {
  margin-left: 20px;
  margin-right: 20px;
  font-size: calc(5px + 1vw);
  color: #888888;
}

@media screen and (min-width: 900px) {
  .Footer a {
    font-size: 14px;
  }
}

.Blocker {
  width: 1px;
  height: 50%;
  background-color: #888888;
}

.FooterIcons {
  display: flex;
  flex-direction: row;
}

.FooterIcons a {
  margin: 0;
  margin-left: 15px;
  margin-right: 2px;
  padding-top: 3px;
}

.FooterIcons svg {
  height: calc(5px + 1vmin);
  width: calc(5px + 1vmin);
}
